






























































































































































































































































































import Vue from 'vue';
import { message } from 'ant-design-vue';
import moment from 'moment';
import * as api from '@/api/tenantManage';
import { Empty } from 'ant-design-vue';
export default Vue.extend({
  name: 'tenantManageDetails',
  data() {
    return {
      basicInfo: {
        sysUserBaseInfoDTO: {},
      },
      tenantInfoSpinning: false,
      simpleImage: Empty['PRESENTED_IMAGE_SIMPLE'],
      previewVisible: false,
      previewImage: '',
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 50,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
        onChange: (page, pageSize) => {
          // console.log('page:', page);
          // console.log('pageSize:', pageSize);
          this['getSubAccountDataList'](page, pageSize, this.$route.query.id);
        },
        onShowSizeChange: (current, size) => {
          // console.log('page:', current);
          // console.log('pageSize:', size);
          this['getSubAccountDataList'](1, size, this.$route.query.id);
        },
      },
      subAccountColumns: [
        {
          title: '姓名',
          dataIndex: 'realName',
        },
        {
          title: '登录账号',
          dataIndex: 'userCode',
        },
        {
          title: '手机号码',
          dataIndex: 'mobile',
        },
        {
          title: '创建人',
          dataIndex: 'recUserRealName',
        },
        {
          title: '创建时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
        },
        {
          title: '最后登录',
          dataIndex: 'lastLoginTime',
          scopedSlots: { customRender: 'lastLoginTime' },
        },
        {
          title: '用户状态',
          dataIndex: 'recStatus',
          scopedSlots: { customRender: 'recStatus' },
        },
      ],
      subAccountData: [],
      appEmpowerData: [],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getTenantDetail(this.$route.query.id);
      this.getAgenciesApplication(this.$route.query.id);
      this.getSubAccountDataList(1, 10, this.$route.query.id);
    }
  },
  methods: {
    moment,
    // 转换认证信息
    createAuditStateText(val) {
      if (val == '0') {
        return '审核中';
      } else if (val == '1') {
        return '已认证';
      } else {
        return '认证不通过';
      }
    },
    createRecStatusText(val) {
      if (val == 'A') {
        return '正常';
      } else if (val == 'I') {
        return '停用';
      } else if (val == 'D') {
        return '删除';
      } else {
        return val;
      }
    },
    handleImg(src) {
      const reg = /www.hebztb.com/g;
      let handImg = src;
      if (src && reg.test(src)) {
        const regImg = src.replace(/&amp;/gi, '&');
        handImg = regImg.replace(/([^:])\/\//g, '$1/');
      }
      return handImg;
    },
    getTenantDetail(agencyCode) {
      this.tenantInfoSpinning = true;
      api.getTenantDetail(agencyCode).then((res) => {
        if (res.status === 200) {
          this.basicInfo = res.result;
          this.tenantInfoSpinning = false;
        } else {
          message.error(res.message);
        }
      });
    },
    getAgenciesApplication(agencyCode) {
      api.getAgenciesApplication(1, 9999, agencyCode).then((res) => {
        if (res.status === 200) {
          // console.log('getAgenciesApplication');
          // console.log(res.result);
          this.appEmpowerData = res.result.list;
        } else {
          message.error(res.message);
        }
      });
    },
    getSubAccountDataList(current: number, pageSize: number, agencyCode) {
      api.getSubAccountDataList(current, pageSize, agencyCode).then((res) => {
        if (res.status === 200) {
          // console.log('getSubAccountDataList');
          // console.log(res.result);
          this.pagination.total = res.result.total;
          this.subAccountData = res.result.list;
        } else {
          message.error(res.message);
        }
      });
    },
    tabChange(key) {
      console.log(key);
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(imageUrl: string) {
      this.previewImage = this.handleImg(imageUrl);
      this.previewVisible = true;
    },
    copyToClipboard(mainAccount: string) {
      const copyContent = mainAccount;
      // navigator clipboard 需要https等安全上下文
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(copyContent).then(
          () => {
            this.$message.success('复制成功');
          },
          () => {
            this.$message.success('您的浏览器暂不支持此功能');
          }
        );
      } else {
        // 创建text area
        let textArea = document.createElement('textarea') as any;
        textArea.value = copyContent; // 使text area不在viewport，同时设置不可见
        textArea.style.position = 'absolute';
        textArea.style.opacity = 0;
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand('copy') ? res : rej;
          textArea.remove();
          this.$message.success('复制成功!');
        });
      }
    },
    examine(id: string) {
      // console.log('examine');
      this.$router.push({
        path: '/platform/certificationAudit/details',
        query: { id: id },
      });
    },
  },
});
